import React from 'react';

import LogDataTable from '../components/LogDataTable';

export default function LogPage() {
  return (
    <>
      <h1>LOG</h1>
      <LogDataTable />
    </>    
  );  
}