import React from 'react';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export default function BillingCard({
  title,
  subTitle,
  pricing,
  discription
}) {
  return (
    <Card sx={{ 
      margin: 1,
      padding: 1
    }}>
      <Typography variant="h5">
        {title}
      </Typography>

      <Typography variant="h6">
        {subTitle}      
      </Typography>

      <Typography variant="h3">
        {pricing}
      </Typography>

      {discription && discription.map((d) => {
        return (
          <Typography variant="h6">
            {d}
          </Typography> 
        )
      })}
    </Card>
  );
}