import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import GoogleMapReact from 'google-map-react';
import MapIcon from '@mui/icons-material/Map';
import NoiseControlOffIcon from '@mui/icons-material/NoiseControlOff';

import styled from 'styled-components';

import estimation from '../controller/estimation';
import { UserContext } from '../contexts/UserContext';

const MarkerIcon = styled(NoiseControlOffIcon)`
  color: rgb(90, 206, 250);
`

const Marker = () => (
  <div>
    <MarkerIcon />
  </div>
);

function LoadMap({center, positions}) {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyARGI_n8CyJ75o_QNFQwHUpbof4wXmwot4" }}
        defaultCenter={center}
        defaultZoom={15}
      >
        {positions.map(position => {
          return (
            <Marker 
              key={position.id}
              lat={position.latitude}
              lng={position.longitude}
              position={position}
            />
          )
        })}
        
      </GoogleMapReact>
    </div>
  );
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MapDialog({estimationId, center}) {
  const [open, setOpen] = React.useState(false);
  const [estimationLog, setEstimationLog] = React.useState([]);
  const user = React.useContext(UserContext);
  
  React.useEffect(()=> { 
    async function getPositions() {
      const res = await estimation.getPositions(user, estimationId);
      setEstimationLog(res.data);
    }
    getPositions();
  },[estimationId, user]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <MapIcon />
      </IconButton>
      
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Map
            </Typography>            
          </Toolbar>
        </AppBar>
        <LoadMap center={center} positions={estimationLog} />
      </Dialog>
    </div>
  );
}