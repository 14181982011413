import React, { useContext, useEffect } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';

import DevicePage from './pages/DevicePage';
import LogPage from './pages/LogPage';
import MapPage from './pages/MapPage';
import OverViewPage from './pages/OverViewPage';
import SettingsPage from './pages/SettingsPage';
import SignInPage from './pages/SignInPage';
import KeyPage from './pages/KeyPage';
import BillingPage from './pages/BillingPage';
import MainPage from './pages/MainPage';

import SideBar from './components/SideBar';
import Header from './components/Header';

import { UserContext } from './contexts/UserContext';

import auth from './controller/auth';

import './App.css';

function Contents({children}) {  
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      {children }
    </Box>
  );
}

function App() {
  const user = useContext(UserContext);

  useEffect(() => {
    const email = localStorage.getItem("userEmail");

    if ( email  !== null && 
         user.data.isLogin === false
    ) { 
      slientLogin(email);
    }

    async function slientLogin(email) {
      const res = await auth.slientRefresh();
      user.setUser(email, res.data.accessToken);
    }
  }, [user]);

  return (    
    <Router>        
      {!user.data.isLogin && (                
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/signin" element={<SignInPage />} />
        </Routes>
      )}
      {user.data.isLogin && ( 
        <Box sx={{ display: 'flex', height: '100vh' }}>
          <CssBaseline />
          <Header />
          <SideBar />
          <Contents >
            <Routes>          
              <Route path="/"         element={<OverViewPage />} />
              <Route path="/log"      element={<LogPage />} />                    
              <Route path="/map"      element={<MapPage />} />                      
              <Route path="/settings" element={<SettingsPage/> } />                    
              <Route path="/device"   element={<DevicePage />} />          
              <Route path="/api"      element={<KeyPage /> } />
              <Route path="/billing"  element={<BillingPage /> } />
            </Routes>                        
          </Contents>
        </Box>      
      )}      
    </Router>
  );
}

export default App;