import axiosConfig from './axiosConfig';

const getAPIKey = async (user) => {
  try {
    const res = await axiosConfig({
      url: `/api/key`,
      method: 'get',
      params: {
        email: user.data.email
      }
    });
    return res;
  }catch (error) {
    throw error;
  }
}

const createAPIKey = async (user) => {
  try {
    const res = await axiosConfig({
      url: '/api/key',
      method: 'post',
      data: {
        email: user.data.email
      }
    });
    return res;
  }catch (error) {
    throw error;
  }
}

const deleteAPIKey = async (user) => {
  try {
    const res = await axiosConfig({
      url: '/api/key',
      method: 'delete',
      data: {
        email: user.data.email
      }
    });
    return res;
  }catch (error) {

  }
}

const key = {
  getAPIKey,
  createAPIKey,
  deleteAPIKey
}

export default key;