import React from 'react';

import ProfileSettings from '../components/ProfileSettings';

export default function SettingsPage() {
  return ( 
    <div>
      <h1>SettingsPage</h1>
      <ProfileSettings />
    </div>
  );
}