import React, { useContext, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import styled from 'styled-components';

import { useNavigate } from "react-router-dom";

import { UserContext } from '../contexts/UserContext';
import { ViewContext } from '../contexts/ViewContext';

const UserProfile = styled.div`
  margin: 5px;
`

function UserInfo({
  user
}) {
  const [anchorElUser, setAnchorElUser] = useState(null);  
  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
  return (
    <UserProfile>
      <IconButton 
        onClick={handleOpenUserMenu}        
      >
        <Avatar
          aria-label="user avatar"
          aria-controls="menu-appbar"
          aria-haspopup="true"        
        >
          {user.data.email.charAt(0)}
        </Avatar>
      </IconButton>      
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key="signOut" onClick={() => {
          user.signout();
          localStorage.removeItem("userEmail");
          navigate("/");
        }}>
          <Typography textAlign="center">SignOut</Typography>
        </MenuItem>        
      </Menu>
    </UserProfile>    
  );
}

export default function Header() {
  const viewState = useContext(ViewContext);
  const user = useContext(UserContext);

  const navigate = useNavigate();

  return (    
    <AppBar  position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'flex', md: 'flex' } }}
        >
          APOLO BETA
        </Typography>
        <Box sx={{flexGrow: 1}} >
          {
            user.data.isLogin && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  viewState.toggleSideBarButton()
              }}>
                <MenuIcon />  
              </IconButton>
            )
          }
        </Box>           
        { user.data.isLogin && (
            <UserInfo user={user} />
          )
        }
        { !user.data.isLogin && (
            <Button 
              variant="contained"
              onClick={() => {
                navigate('/signin');
              }}
            >
              Siginin
            </Button>
          )
        }        
      </Toolbar>
    </AppBar>
  );
}