import axiosConfig from './axiosConfig';

const getLogs = async (user) => {
  try {
    const res = await axiosConfig({
      method: 'get', 
      url: '/api/estimation',
      params : {
        email : user.data.email
      }
    });
    return res.data;
  }catch (error) {
    throw error;
  }
}

const deleteLogs = async (user,ids) => {
  try {
    const res = await axiosConfig({
      method: 'delete',
      url: '/api/estimation',
      params: {
        email: user.data.email
      },
      data: {
        estimationId:ids
      }
    });
    return res;
  }catch (error) {
    throw error;
  }
}

const getPositions = async (user, estimationId) => {
  try {
    const res = await axiosConfig({
      method: 'get',
      url: '/api/position',
      params: {
        email: user.data.email,
        estimationId: estimationId
      }
    });  
    return res;
  }catch (error) {
    throw error;
  }  
}

const getActivate = async (email) => {
  try {
    const res = await axiosConfig({
      method: 'get',
      url: '/api/estimation/activate',
      params: {
        email: email
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
}

const getAmountofUsed = async (email) => {
  try {
    const res = await axiosConfig({
      method: 'get',
      url: '/api/estimation/amount',
      params: {
        email: email
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
}

const estimation = {
  getLogs,
  deleteLogs,
  getPositions,
  getActivate,
  getAmountofUsed
}

export default estimation;