import React from 'react';
import styled from 'styled-components';

import GenerateKey from '../components/GenerateKey';
import KeyStore from '../contexts/KeyContext';

const ContentLayout = styled.div`
  margin : 1vh;    
`
export default function KeyPath() {
  return (
    <KeyStore>
      <ContentLayout >
        <GenerateKey />        
      </ContentLayout>    
    </KeyStore> 
  )
}