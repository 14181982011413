import React, { useState, useEffect} from 'react';

import {
  useQuery
} from 'react-query';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import styled from 'styled-components';

import CardView from '../components/CardView';
import ChartView from '../components/ChartView';
import Announcement from '../components/Announcement';

import estimation from '../controller/estimation';

const Container = styled.div`
  padding: 5px;
`

const PageTitle = styled(Typography)`
  padding: 5px;
  margin-bottom: 10px;
`

export default function OverViewPage() {  
  const [openModel, setOpenModal] = useState(true);
  const [intervlaTime ] = useState(1000);
  
  useEffect(()=>{
    const isOpenTime = () => {
      const modalClosingTime = localStorage.getItem('modalClosingTime');
      const ONEDAY = 1 * 1000 * 86400;
      if (modalClosingTime && parseInt(modalClosingTime) + ONEDAY > Date.now()) {
        return false;
      }else {
        return true;
      }
    }

    if (!isOpenTime()) {
      closeModal();
    }
  }, []);

  const activate = useQuery(
    'activate',
    async () => {
      const email = localStorage.getItem("userEmail");
      const res = await estimation.getActivate(email);
      return res.data;
    },
    {
      refetchInterval: intervlaTime,
    }
  );

  const amount = useQuery(
    'amount',
    async () => {
      const email = localStorage.getItem("userEmail");
      const res = await estimation.getAmountofUsed(email);
      return res.data;
    },
    {
      refetchInterval: intervlaTime,
    }
  );

  const closeModal = () => {
    setOpenModal(false);
  }

  const closeForOneDay = () => {
    localStorage.setItem('modalClosingTime', Date.now());
    closeModal();
  }

  const monthlyData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        type: 'bar',
        label: '월별 사용량',
        backgroundColor: 'rgb(255, 99, 132)',
        data: [2000, 5403, 4200, 6000, 5984, 4923, 7000],
        borderColor: 'red',
        borderWidth: 2,
      },
    ],
  };

  const hourlyData = {
    labels: [
      '1:00', 
      '2:00', 
      '3:00', 
      '4:00', 
      '5:00', 
      '6:00', 
      '7:00', 
      '8:00',
      '9:00',
      '10:00',
      '11:00',
      '12:00'
    ],
    datasets: [
      {
        type: 'line',
        label: '시간별 요청량',
        backgroundColor: 'rgb(25, 99, 132)',
        data: [100, 140, 50, 60, 200, 150, 300, 100, 140, 50, 60, 200, 150, 300],
        borderColor: 'rgb(25, 99, 132)',
        borderWidth: 2,
      },
    ],
  };

  return (     
    <Container>
      <Announcement open={openModel} handleClose={closeModal} handleCloseForOneDay={closeForOneDay} />
      <PageTitle variant='h4'>
        OverView
      </PageTitle>

      <Grid
        container
        alignItems="start"
      >
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardView 
            title='Activate User' 
            subTitle='측위를 사용중인 User'
            icon={<GroupIcon />}
            contentsText={ activate.data&& activate.data.countActivate}
          />                 
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardView 
            title='청구 금액' 
            subTitle='사용금액 및 사용량'
            icon={<AttachMoneyIcon />}
            contentsText={ 
              amount.data && amount.data.positionCount
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardView 
            title='청구 금액' 
            subTitle='사용금액 및 사용량'
            icon={<AttachMoneyIcon />}
            contentsText={ 
              amount.data && amount.data.positionCount
            }
          />
        </Grid>
        
        <Grid item xs={12}>
          <ChartView data={monthlyData}/>
        </Grid>

        <Grid item xs={12}>
          <ChartView data={hourlyData}/>
        </Grid>
      </Grid>
    </Container>
  );
}