import React, { createContext, useState} from 'react';

import { useSnackbar } from 'notistack';

import auth from '../controller/auth';

export const UserContext = createContext();
export default function UserStore(props) {
  const { enqueueSnackbar } = useSnackbar();

  const initState = {
    isLogin : false,
    isLoding : false,
    email : "",
    token : ""
  }

  const [data, setData] = useState(initState);

  const showNotification = ({message, variant}) => {
    enqueueSnackbar(message, {variant: variant});    
  };

  const setUser = (email, token) => {
    setData({
      ...data,
      email : email,    
      token : token,
      isLogin : true
    });
  }

  const setToken = (newToken) => {
    setData({
      ...data,
      token : newToken
    });
  }

  const setUserLogOut = () => {
    setData({
      ...data,
      isLogin:false,
      email: "",
      token: ""
    });
  }

  const signin = async (email, password) => {
    try {
      const res = await auth.signIn({email, password})        
      setUser(email, res.data.accessToken);
      localStorage.setItem('userEmail', email);
    }catch (error) {
      showNotification({message: "Signin error", variant: "error"});
    }
  }

  const signup = async (user, callback) => {
    try {
      await auth.signUp(user);
      callback("/");
    }catch (error) {
      showNotification({message: "Signup error", variant: "error"});
    }    
  }

  return (
    <UserContext.Provider 
      value={{
        data, 
        setUser   : setUser,
        setToken  : setToken,
        signin : signin,
        signup : signup,
        signout : setUserLogOut
      }} 
    >
      {props.children}
    </UserContext.Provider>
  );
}