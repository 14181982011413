import React from 'react';

import { Chart as ChartJS } from 'chart.js/auto'; // eslint-disable-line no-unused-vars
import { Line } from 'react-chartjs-2';
import { Paper } from '@mui/material';

import styled from 'styled-components';

const Container = styled(Paper)`
  margin: 10px;
  padding: 10px;
`

export default function ChartView({data}) {
  return (
    <Container>
      <Line 
        type='line' 
        data={data} 
        height={50}
      />      
    </Container>
  );
}