import React, {useContext, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';

import { useNavigate } from "react-router-dom";

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import KeyIcon from '@mui/icons-material/Key';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

import { ViewContext } from '../contexts/ViewContext';

function NavList() {
  const navigate = useNavigate();
  const navData = [
    {
      text : "Overview",
      path : "/",
      icon : <SummarizeIcon />
    },
    {
      text : "API KEY",
      path : "/api",
      icon : <KeyIcon />
    },
    {
      text : "Log",
      path : "/log",
      icon : <FolderOpenIcon />
    },    
    {
      text : 'Billing',
      path : '/billing',
      icon : <LocalAtmIcon />
    },
    {
      text : 'Settings',
      path : '/settings',
      icon : <SettingsIcon />
    },
  ]
  
  return (
    <List>        
      {navData.map((nav) => (
        <ListItemButton key={nav.text} onClick={() => {navigate(nav.path)}}>
          <ListItemIcon>
            {nav.icon}
          </ListItemIcon>
          <ListItemText primary={nav.text} />  
        </ListItemButton > 
      ))}   
    </List>      
  );
}

export default function SideBar() {
  const viewState = useContext(ViewContext);
  const hidingSideBar = useMediaQuery('(min-width:400px)');

  useEffect(() => {
    if(hidingSideBar) {
      viewState.openSideBar();
    }else {
      viewState.closeSideBar();
    }
  },[hidingSideBar]);// eslint-disable-line react-hooks/exhaustive-deps


  let drawerWidth = 240
  if (viewState.isOpenSideBar) {
    drawerWidth = 240;
  }else {
    drawerWidth = 0;
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto', p: 1}}>                      
        <NavList />
        <Divider />                
      </Box>
    </Drawer>
  );
}