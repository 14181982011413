import React from 'react';

import Toolbar from '@mui/material/Toolbar';

import Header from '../components/Header';
import Explanation from '../components/Explanation';
import Footer from '../components/Footer';

export default function MainPage() {
  return (
    <div >
      <Toolbar />
      <Header />
      <Explanation />
      <Footer />
    </div>
  );
}