import React from 'react';

import Typography from '@mui/material/Typography';

import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
`

const FooterText = styled(Typography)`
  margin: 10px;
  padding: 10px;
  text-align: center;
`

export default function Footer() {
  return (
    <Container>
      <FooterText variant='body2'>
        02-6925-1516 ppsoln@ppsoln.com <br />
        서울특별시 금천구 서부샛길 606, 대성디폴리스지식산업센터 B동 2311호 <br />
        <br />
        <br />
        ©PP-SOLUTION INC. ALL RIGHTS RESERVED.
      </FooterText>      
    </Container>
  );
}