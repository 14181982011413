import React from 'react';

import Typography from '@mui/material/Typography';

import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`

const BannerImage = styled.img`  
  width: 100%;
  height: 600px;
  object-fit: cover;
`

const BannerTitle = styled(Typography)`
  position: absolute;
  bottom: 30%;
  left: 20px;
  color: rgb(255, 192, 0);
`

const BannerText = styled(Typography)`
  position: absolute;
  bottom: 15%;
  left: 20px;
  color: white;
`

const LogImage = styled.img`
  position: absolute;
  max-height: 6vh;
  bottom: 80%;
  left: 85%;
`

export default function Banner() {
  return (
    <Container >
      <BannerImage src="images/banner.jpg" alt="banner image" />
      <LogImage src="images/logo.png" alt="ppsoln logo" />
      <BannerTitle variant='h1'>
        APOLO  
      </BannerTitle>
      <BannerText variant='h2'>
        Accurate POsition & LOcation SERVICE
      </BannerText>
    </Container>
  );
}