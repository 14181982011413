import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import { 
  OutlinedInput, 
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import styled from 'styled-components';

import { UserContext } from '../contexts/UserContext';

const Container = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => `url(${props.src})`};
  background-size: 100%;
`

const Contents = styled(Grid)`
  height: 100vh;
`

const SignInCard = styled(Card)`
  margin : 5vh;
  padding : 5vh;
`

const SignInButton = styled(Button)`
  text-transform: 'none';
`

export default function SignInPage() {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword : !values.showPassword,
    })
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePassword = (value) => {
    setValues({
      ...values,
      password: value
    });
  }

  const handleEmail = (value) => {
    setValues({
      ...values,
      email: value
    })
  }
  

  const handleSignIn = () => {    
    if (
      values.email.length === 0 || 
      values.password.length === 0
    ) {



    }else {
      user.signin(values.email, values.password, user);
      navigate("/");
    }
  }

  const handlePressEnter = (e) => {
    if (e.key === "Enter") {
      handleSignIn();
    }
  }

  const user = useContext(UserContext);  

  return (   
    <Container src="images/banner.jpg">
      <Contents 
        container
        direction="row"
        justifyContent="center"
        alignItems="center">

        <Grid item xs={12} xl={4} lg={6} md={8} >          
          <SignInCard>
            <Grid container spacing={3}>
              {/*  title */}
              <Grid item xs={12} textAlign="center">
                <Typography variant="h3" marginBottom>
                  APOLO BETA
                </Typography>
                <Divider />
              </Grid>
              
              {/* sigin title */}
              <Grid item xs={12} textAlign="center">
                <Typography variant="h5">
                  Sign in with Email address
                </Typography>
              </Grid>

              {/* input email */}
              <Grid item xs={12}>
                <TextField 
                  fullWidth 
                  id="email" 
                  type="text" 
                  label="Email address"
                  onChange={(event) => {              
                    handleEmail(event.target.value);
                  }}
                  onKeyPress={handlePressEnter}
                />              
              </Grid>

              {/* input password */}
              <Grid item xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput 
                    id="password"
                    fullWidth 
                    type={values.showPassword ? 'text' : 'password'}
                    onChange={(event) => {
                      handlePassword(event.target.value);
                    }}   
                    onKeyPress={handlePressEnter} 
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    } 
                    label="Password"                 
                  />                   
                </FormControl>
              </Grid>

              {/* Forgot Password */}
              <Grid 
                item 
                xs={12} 
                textAlign="end">
                {/* <ForgotPassordButton 
                  onClick={() => {
                    console.log("Forgot password click!!!!")
                  }}>
                  Forgot Password?
                </ForgotPassordButton>                           */}
              </Grid>
              
              <Grid item xs={12} textAlign="center">
                <SignInButton 
                  fullWidth
                  variant="contained" 
                  onClick={handleSignIn}> 
                  <Typography component="div" >
                    Sign In
                  </Typography>
                </SignInButton>
              </Grid>

              <Grid item xs={12}> 
                <Divider variant="middle" />                
              </Grid>

              {/* <Grid item xs={12} textAlign="center">               
                  <SignUpButton 
                    component="div"
                    onClick={() => {
                      navigate("/beta");
                    }}>
                    Don't have an account?
                  </SignUpButton>
              </Grid>                                         */}
            </Grid>          
          </SignInCard>
        </Grid>            
      </Contents>    
    </Container>    
  );
}