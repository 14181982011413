import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Announcement({open, handleClose, handleCloseForOneDay}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5">
          사용 시 유의사항
        </Typography>          

        <Typography variant='h6'>
          1. 현재 개발 중인 서비스로 업데이트가 자주 예정되어 있습니다. <br/>
          2. 사용 중 에러가 발생할 수 있으며 에러 발생 시 제공받은 앱을 재부팅하시면 됩니다.<br />
          3. 서비스 업데이트 시 데이터베이스가 초기화 될 수 있습니다.<br/>            
          4. 업데이트 및 테스트 중에는 사용이 불가합니다.<br/>          
        </Typography>            

        <Button onClick={handleCloseForOneDay}>
          오늘 하루 보지 않기
        </Button>

        <Button onClick={handleClose}>
          닫기
        </Button>
      </Box>
    </Modal>
  );
}