import React from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import styled from 'styled-components';

const GradationPaper = styled(Paper)`
  background: linear-gradient(
    to right, 
    rgba(7,65,173,1), 
    rgba(53,144,213,1)
  );  
  margin: 10px;
  padding: 10px;
`

const Title = styled(Typography)`
  color: white;
  margin: 5px;
`

const SubTitle = styled(Typography)`
  color: rgba(242,242,242,95);
  margin: 5px;
`

const ContentsText = styled(Typography)`
  color: white;
  margin: 5px;
`

export default function CardView({title, subTitle, contentsText, icon}) {
  return (
    <GradationPaper>
      <Grid 
        container
        alignItems="center"        
      >
        <Grid item xs={8}>          
          <Grid container>
            <Grid item xs={12}>
              <Title variant='h6'>
                {title} 
              </Title>
            </Grid>
            <Grid item xs={12}>
              <SubTitle variant="h7">
                {subTitle}
              </SubTitle>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <ContentsText variant="h4">            
            {icon} {contentsText}
          </ContentsText>
        </Grid>        
      </Grid>
    </GradationPaper>
  );
}