import React from 'react';

import Grid from '@mui/material/Grid';

import BillingCard from '../components/BillingCard';


export default function BillingPage() {
  return (
    <Grid
      container
      direction='row'
      spacing={2}
    >
      <Grid item xs={4}>
        <BillingCard 
          title="Developer"
          subTitle="Limited SSR Estimation for Developer"
          pricing="$ 0/mo"
          discription={[
            "SSR Estimation",
            "100k Estimation query free",
            "free user"
          ]}
        />
      </Grid>
      <Grid item xs={4}>
        <BillingCard 
          title="Team"
          subTitle="SSR Estimation for Team"
          pricing="$ 30/mo"
          discription={[
            "SSR Estimation",
            "100k Estimation query free",
            "free user"
          ]}
        />
      </Grid>
      <Grid item xs={4}>
        <BillingCard 
          title="Business"
          subTitle="SSR Estimation for Business"
          pricing="$ 100/mo"
          discription={[
            "SSR Estimation",
            "unlimited Estimation query",
          ]}
        />
      </Grid>
    </Grid>
  )
}