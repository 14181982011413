import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import styled from 'styled-components';

import Banner from '../components/Banner';

const Container = styled.div`
  position: relative;
`

const ExplanationText = styled(Typography)`
  padding: 10px;
  margin: 10px;
`

const ExplanationImage = styled.img`
  width: 100%;
  height: 600px;
  object-fit: cover;
`

export default function Explanation() {
  return (
    <Container>
      <Banner />
      <Grid 
        container 
        variant="row"
      >
        <Grid 
          item
          xs={6}
        >
          <ExplanationText variant='body2'>
            Accurate POsition & Location Service의 약자로 APOLO는 PaaS의 일종입니다. <br /><br />
            서버 기반의 정밀 측위 서비스를 지원하며 스마트폰, 측량 장비에서 Raw Data를 전송 받아 서비에서 SSR 보정정보를 적용한 정밀위치를 계산하여 사용자에게 제공해주는 정밀위치정보 PaaS 입니다.<br />
            별도의 장치 없이 스마트폰만으로 1m 내외 정확도 위치정보 확보 가능. <br/><br />
            스마트폰 소모 전력, 가격 측면에서 높은 효율성 <br />
          </ExplanationText>
        </Grid>

        <Grid 
          item
          xs={6}
        >
          <ExplanationText variant='body2'>
            - 별도 장치 없이 스마트폰만으로 1m 내외 정확도 확보 <br />
            - 서버 연산이기 때문에 스마트폰 소비 전력 절약 <br />
            - 무제한 사용자를 위한 최신 SSR 보정정보 활용 <br />
          </ExplanationText>
        </Grid>        

        <Grid 
          item
          xs={12}
        >
          <ExplanationText variant='h5'>
            스마트폰의 Raw Data &#62; APOLO 전달 &#62; APOLO가 SSR 정밀 좌표를 계산 &#62; 스마트폰으로 전송
          </ExplanationText>
        </Grid>

        <Grid 
          item
          xs={12}
        >
          <ExplanationImage src="images/ex.png" alt="explanation image" />
        </Grid>
      </Grid>   
    </Container>
  );
}