import React, {
  useEffect, 
  useContext, 
  useState
} from 'react';

import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import visuallyHidden from '@mui/utils/visuallyHidden';
import Chip from '@mui/material/Chip';

import dayjs from 'dayjs';

import { UserContext } from '../contexts/UserContext';
import estimation from '../controller/estimation';

import MapDialog from './MapDialog';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'id', numberic: true, disablePadding: false, label: 'ID' },
  { id: "latitude",numberic: true, disablePadding: false,label: "Latitude" },
  { id: "longitude",numberic: true, disablePadding: false,label: "Longitude" },
  { id: "height",numberic: true, disablePadding: false,label: "Height" },
  { id: "deviceName",numberic: true, disablePadding: false,label: "Device" },
  { id: "estimationType",numberic: true, disablePadding: false,label: "Estimiation Type" },
  { id: "usageTime",numberic: true, disablePadding: false,label: "Usage Time" },
  { id: "startTime", numberic: true, disablePadding: false, label: "Start Time"},
  { id: "isActivate", numberic: true, disablePadding: false, label: "Activate" },
  { id: "map", numberic: true, disablePadding: false, label: "Map" }
]


function LogDataTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = 
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label' : 'select all data',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numberic ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ?  (
                <Box component="span" sx={visuallyHidden} >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

LogDataTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const LogDataTableToolbar = (props) => {
  const { numSelected, selected, setRows, setSelected } = props;
  const user = useContext(UserContext);
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1},
        ...(numSelected > 0 && {
          bgcolor: (theme) => 
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%'}}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%'}}
          color="inherit"
          variant="tableTitle"
          component="div"
        >
          Estimation Log
        </Typography>
      )}

      {numSelected > 0 ? ( 
        <Tooltip title="Delete">
          <IconButton
            onClick={async () => {              
              await estimation.deleteLogs(user, selected);       
              const logs = await estimation.getLogs(user);
              setRows(logs);     
              setSelected([]);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

LogDataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function LogDataTable() {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const user = useContext(UserContext);

  useEffect(()=>{
    async function fetchLogs() {
      try{
        const logs = await estimation.getLogs(user);
        setRows(logs);
      }catch(error) {
        throw error;
      }      
    }
    fetchLogs();
  }, [user]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc' ;
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return ;
    }
    setSelected([]);
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    }else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));    
    }else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0,-1));
    }else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = 
    page > 0 ? 
      Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0; 

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2}}>
        <LogDataTableToolbar numSelected={selected.length} selected={selected} setSelected={setSelected} setRows={setRows} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <LogDataTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const llh = [row.latitude, row.longitude, row.height];

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        commponet="th"
                        id={labelId}
                        scope="row"
                        align="right"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="right">{llh[0]}</TableCell>
                      <TableCell align="right">{llh[1]}</TableCell>
                      <TableCell align="right">{llh[2]}</TableCell>
                      <TableCell align="right">{row.deviceName}</TableCell>
                      <TableCell align="right">{row.estimationType}</TableCell>
                      <TableCell align="right">{row.usageTime}</TableCell>
                      <TableCell align="right">
                        {dayjs(row.createdAt).format('YYYY MM-DD HH:mm:ss')}
                      </TableCell>
                      <TableCell align="right">
                        {row.isActivateEstimation === 1 ? (
                          <Chip color="primary" label="running" />
                        ) : (
                          <Chip label="done" />
                        )}
                      </TableCell>  
                      <TableCell align="right">
                        <Box 
                          sx={{
                            zIndex: 5
                          }}
                        >
                          <MapDialog 
                            estimationId={row.id} 
                            center={{lat:llh[0], lng:llh[1]}} 
                          />                     
                        </Box>                        
                      </TableCell>                    
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow 
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}          
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10 , 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />        
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense Padding"
      />
    </Box>
  );
}