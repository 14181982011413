import React, { createContext, useState } from 'react';
export const ViewContext = createContext();

export default function ViewStore(props) {
  const initState = {
    sideBarOpen : true,    
  }

  const [viewState, setViewState] = useState(initState);

  const toggleSideBarButton = () => {    
    setViewState({
      ...viewState,
      sideBarOpen: !viewState.sideBarOpen
    });    
  }

  const openSideBar = () =>{
    setViewState({
      ...viewState,
      sideBarOpen: true
    })
  }

  const closeSideBar = () => {
    setViewState({
      ...viewState,
      sideBarOpen: false
    })
  }

  return (
    <ViewContext.Provider
      value={{
        isOpenSideBar: viewState.sideBarOpen,
        openSideBar,
        closeSideBar,
        toggleSideBarButton
    }} >
      {props.children}
    </ViewContext.Provider>
  )
}