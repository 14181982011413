import axios from "axios";

let url;
if (process.env.REACT_APP_ENV === "development") {
  url = "http://localhost:3000";
} else {
  url = "https://api.gnsson.com";
}

const apiAxios = axios.create({
  baseURL: url,
  timeout: 5000,
});

apiAxios.interceptors.request.use(
  function (config) {
    config.withCredentials = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default apiAxios;
