import axiosConfig from './axiosConfig';

const JWT_EXPIRY_TIME = 1 * 3600 * 1000;

const signIn = async (signInInfo) => {
  try {
    const res = await axiosConfig({
      url: '/api/auth/signin',
      method: 'post',
      data: signInInfo
    });

    axiosConfig.defaults.headers.common['AccessToken'] = res.data.accessToken;

    setTimeout(slientRefresh, JWT_EXPIRY_TIME - 60000);

    return res;
  }catch (error) {
    throw error;
  }
}

const signUp = async (user) => {
  try{
    const res = await axiosConfig({
      url: '/api/auth/signup',
      method: 'post',
      data: user
    });
    return res;
  }catch (error) {
    throw error;
  }
}

const slientRefresh = async () => {
  try {
    const res = await axiosConfig({
      url: '/api/auth/slient-refresh',
      method: 'post'
    });
    axiosConfig.defaults.headers.common['AccessToken'] = res.data.accessToken;
    return res;
  }catch (error) {
    throw error;
  }
}

const auth = {
  signIn,
  signUp,
  slientRefresh
}

export default auth
