import React, { createRef } from 'react';
import ReactDOM from 'react-dom';

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';
import { Button } from '@mui/material';


import './lib/i18n';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import UserStore from './contexts/UserContext';
import ViewStore from './contexts/ViewContext';

require('dotenv').config();

const notistackRef = createRef();

const queryClient = new QueryClient();

const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
}

ReactDOM.render(
  <React.StrictMode> 
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        maxSnack={1}
        ref={notistackRef}
        action={(key) => {
          <Button onClick={onClickDismiss(key)} >
            X
          </Button>        
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        TransitionComponent={Slide}
      >                
        <ViewStore>
          <UserStore>
            <App />
          </UserStore>    
        </ViewStore>            
      </SnackbarProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
