import React, { createContext, useState } from 'react';

export const KeyContext = createContext();

export default function KeyStore(props) {
  const initState = {
    apiKey : "", 
    hasKey : false,   
  }

  const [key, setKey] = useState(initState);

  const setKeyFromServer = (apikey) => {
    setKey({
      ...key,
      apiKey : apikey,
      hasKey : true
    });
  }

  const removeKey = () => {
    setKey({
      ...key,
      apiKey : "",
      hasKey : false
    });
  }

  return (
    <KeyContext.Provider
      value={{
        key,
        setKeyFromServer,
        removeKey
      }}>
        {props.children}
    </KeyContext.Provider>
  );
}