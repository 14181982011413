import React from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function ProfileSettings() {
  return (
    <Box
      component="form"
      noValidate
      autoComplete='off'
    >
      <Typography>
        User profile
      </Typography>
      <TextField
        id="lastName"
        label='Last Name'
      />
      <TextField
        id="firstName"
        label="First Name"
      />
      <TextField
        id="email"
        label="Email"
      />
      <TextField
        id="phoneNumber"
        label="PhoneNumber"
      />

      <Button>
        Update profile
      </Button>
    </Box>
  );
}