import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive'

import { Divider, Grid, Typography } from '@mui/material';
import { Button } from '@mui/material';
import { Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import styled from 'styled-components';

import { KeyContext } from '../contexts/KeyContext';
import { UserContext } from '../contexts/UserContext';

import key from '../controller/key';

const APIKeyField = styled(Paper)`
  margin : 10px;
  padding: 10px;
`

export default function GenerateKey() {
  const keyContext = useContext(KeyContext);
  const userContext = useContext(UserContext);

  const apiKeyText = useRef();

  const [open, setOpen] = useState(false);
  const [keyDialogData, setKeyDialogData] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {    
    setKeyDialogData({
      apikey : "",
      secret : ""
    });    
    setOpen(false);
  };

  useEffect(() => {        
    async function loadAPIKey() {
      if (keyContext.key.hasKey === false) {
        const res = await key.getAPIKey(userContext);
        if(res.status === 200) {
          keyContext.setKeyFromServer(res.data.apikey);
        }        
      }      
    }
    loadAPIKey();
  }, [userContext, keyContext]);

  const onClickCopy = (copyData) => {
    navigator.clipboard.writeText(copyData);
  }

  const onClickDelete = async () => {
    const res = await key.deleteAPIKey(userContext);
    if (res.status === 200) {
      keyContext.removeKey();
    }    
  }

  const isMobile = useMediaQuery({ query: '(max-width: 570px)' });

  return (
    <Box maxWidth={!isMobile ? 600 :  '100%'}>
      <Grid
        container
        rowSpacing={3}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} >
          <Grid 
            container
            direction="row" 
          >
            <Typography  
              variant='h5'
              component="h2">              
              APOLO API KEY
            </Typography> 
            <Button
              onClick={async () => {
                const res = await key.createAPIKey(userContext);
                setKeyDialogData({
                  apikey : res.data.apikey,
                  secret : res.data.secret
                });
                keyContext.setKeyFromServer(res.data.apikey);
                handleClickOpen();    
              }}>
              <AddBoxIcon />   
              { !isMobile && (                                 
                <Typography >
                  Generate API Key 
                </Typography>                                
              )}              
            </Button>                
          </Grid>    
          <Divider />                    
        </Grid>      
        <Grid item xs={12}>     
          {keyContext.key.hasKey && (                    
            <APIKeyField >
              <Grid 
                container    
                spacing={3}                                        
                direction="row"                    
                justifyContent="space-between"
                alignItems="center"        
              >              
                <Grid item xs={10} maxWidth={!isMobile ? 400 :  180}  >
                  <Tooltip title={keyContext.key.apiKey} >
                    <Typography                                         
                      noWrap={true} 
                      ref={apiKeyText}
                    >
                      {keyContext.key.apiKey}
                    </Typography>                      
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="copy" >
                    <IconButton 
                      onClick={()=>{
                        onClickCopy(keyContext.key.apiKey)
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="delete" >
                    <IconButton onClick={onClickDelete}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>                  
                </Grid>                                
              </Grid>                                                
            </APIKeyField>  
          )}                         
        </Grid>               
      </Grid>
      {keyDialogData && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"API KEY & API Secret"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid 
                container
                spacing={3}                                        
                direction="row"
                justifyContent="start"
                alignItems="center"
              > 
                <Grid item xs={12} >
                  <Typography >
                    API KEY
                  </Typography>
                </Grid>
                <Grid item maxWidth={!isMobile ? 400 :  200}>
                  <Tooltip title={keyDialogData.apikey} >
                    <Typography 
                      noWrap={true} 
                      ref={apiKeyText}                       
                    >
                      {keyDialogData.apikey}
                    </Typography>                      
                  </Tooltip>                
                </Grid>
                <Grid item>
                  <Tooltip title="copy" >
                    <IconButton 
                      onClick={()=>{
                        onClickCopy(keyDialogData.apikey);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>              
                <Grid item xs={12} >
                  <Typography >
                    Secret KEY
                  </Typography>
                </Grid>
                <Grid item maxWidth={!isMobile ? 400 :  200}>
                  <Tooltip title={keyDialogData.secret} >
                    <Typography 
                      noWrap={true} 
                      ref={apiKeyText} 
                    >
                      {keyDialogData.secret}
                    </Typography>                      
                  </Tooltip>                
                </Grid>
                <Grid item>
                  <Tooltip title="copy" >
                    <IconButton 
                      onClick={()=>{
                        onClickCopy(keyDialogData.secret);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              Close
            </Button>          
          </DialogActions>
        </Dialog>    
      )}      
    </Box>     
  );
}